.footer {
  background-color: black;
  color: white;
  padding-top: 1%; /* Reduced padding-top for closer alignment to your specs */
}

.footer .max-w-6xl {
  max-width: 1280px; /* Assuming 6xl is around 1280px, adjust as needed */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #707070;
  align-items: top;
  padding: 0.5% 0;
}

.footer-section {
  text-align: left;
  padding-left: 1%;
  display: flex;
  align-items: left;
  flex-direction: column;
  padding-top: 0px;
  padding: 0px 0px 0px 0px;
}

.Copyright {
  font-size: 16px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.icp-link {
  font-size: 8px;
  color: white;
  text-decoration: none;
}
.icp-link-black {
  font-size: 8px;
  color: black;
  text-decoration: none;
}

/* coffee break: modify the back-to-top button to make it act full height */
.back-to-top {
  display: flex; /* Aligns child elements (like text) within the button */
  justify-content: center; /* Centers horizontally within the flex container */
  align-items: top; /* Centers vertically within the flex container */
  text-decoration: none;
  cursor: pointer;
  color: #ccc;
  padding: 10px; /* Adjustable based on your design needs */
  width: 100%; /* Ensures the button stretches to the full container width */
}

.back-to-top:hover {
  color: white;
  text-decoration: underline;
}

.right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 1%;
}

.right-section a {
  color: white;
  text-decoration: none;
}

.right-section a:hover {
  color: #ccc;
  text-decoration: underline;
}
