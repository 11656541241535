.header {
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    transition: background-color 0.3s ease;
    background-color: transparent; /* Default background */
    z-index: 30;
}

.header.scroll {
    background-color: #000; /* Background changes on scroll */
}

.header .flex {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 2xl;
    color: white;
    text-decoration: none;
}


.header #company-name {
    font-size: 25px;  /* Example size, can be adjusted */
    color: white;     /* Make sure the color is explicitly set */
    font-weight: semi-bold; /* Example weight, adjust as needed */
    cursor: pointer;  /* Only if it's meant to be clickable */
    padding: 0;
    background: none;
    border: none;
    transition: color 0.3s ease-in-out;
}

.header #company-name:hover {
    color: #ccc;  /* Example hover effect */
}


.logo img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.nav {
    margin-left: 100px; /* Increase this value as needed for more space */
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust this gap to manage spacing between navigation items */
}

.dropdown {
    position: relative;
    display: inline-block; /* Ensures the dropdown is properly positioned relative to its navigation text */
}

.dropdown .button {
    background: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 5px 10px;
}

.dropdown .button:hover {
    text-decoration: underline;
}

.dropdown-menu {
    display: none;
    position: absolute;
    left: 0; /* Aligns the dropdown menu to the left edge of the .dropdown div */
    background-color: black; /* Adjust background color to match your design */
    min-width: 100%; /* Ensures the dropdown covers the area of the button */
    box-shadow: 0 8px 16px rgba(0,0,0,0.2); /* Optional: adds a shadow for better visibility */
    z-index: 1;
    white-space: nowrap; 
}

/* When the body has not scrolled */
body:not(.scroll) .dropdown-menu {
    background-color: transparent; /* Explicitly set to black */
}

/* When the body has scrolled - Optional changes can be defined here */
body.scroll .dropdown-menu {
    background-color: black; /* Keep black or change if needed */
}

.dropdown-menu a {
    color: white;
    text-decoration: none;
    padding: 8px 12px;
    display: block; /* Makes each link fill the horizontal space of the dropdown */
    text-align: left; /* Aligns text to the left */
}


.dropdown.active .dropdown-menu,
.dropdown:hover .dropdown-menu {
    display: flex;
    flex-direction: column; /* Organizes child elements in a column */
}

.language-button {
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding-right: 5%;
}

.language-button img {
    filter: brightness(0) invert(1);
}
