.hecate {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
}

.hecate .div {
  background-color: #000000;
  height: 110vh;
  overflow-x: hidden;
  position: relative;
  align-items: center;
}

.hecate .main-title {
  color: #ffffff;
  font-family: "Apple SD Gothic Neo-Regular", Helvetica;
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  top: 10%;
  position: relative;
  white-space: nowrap;
  align-items: center;
  text-align: center;
}

.hecate .overlap {
  display: flex; /* Establish flex container */
  justify-content: center; /* Center flex items on the main axis */
  align-items: center; /* Align items on the cross axis */
  position: relative; /* Positioning context for absolute children */
  height: 5vh; /* Adjust height as needed */
  width: 100%; /* Full width */
}

.hecate .line {
  height: 2px;
  left: 0;
  position: absolute;
  top: 92px;
  width: 1920px;
}

.hecate .swap-container {
  display: flex; /* Use flexbox to layout children */
  justify-content: space-around; /* Distribute items evenly */
  align-items: center; /* Align items vertically */
  width: 90%; /* Take the full width of the parent */
}

.hecate .image-swap,
.hecate .video-swap {
  color: #ffffff;
  font-family: "Apple SD Gothic Neo-Regular", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin: 0 10px; /* Spacing between the items */
  flex-grow: 1; /* Allows the items to grow */
  text-align: center; /* Center text */
  position: relative;
}

/* No need for absolute positioning on .video-swap */
.hecate .image-swap {
  font-size: 2rem; /* Size of Image Swap */
  order: 1; /* Order can ensure the position in the flex container */
  margin-right: -40vw;
}

.hecate .video-swap {
  font-size: 2rem; /* Size of Video Swap, made same as Image Swap for symmetry */
  order: 2;
}

@media (max-width: 768px) {
  .hecate .image-swap,
  .hecate .video-swap {
    font-size: 2rem; /* Size of Video Swap, made same as Image Swap for symmetry */
    padding: 0 5px; /* Adjust padding as needed */
  }
}

.hecate .free-group {
  position: absolute;
  transform: rotate(30deg);
  width: 59px;
}

.hecate .free-background {
  background-color: #003144;
  border-radius: 10px;
  height: 30px;
  position: relative;
  width: 57px;
}

.hecate .free {
  color: #ffffff;
  font-family: "BM Jua-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 4px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.hecate .premium-group {
  position: absolute;
  transform: rotate(30deg);
  width: 91px;
}

.hecate .premium-background {
  background-color: #b05923;
  border-radius: 10px;
  height: 30px;
  position: relative;
  width: 89px;
}

.hecate .premium {
  color: #ffffff;
  font-family: "BM Jua-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3px;
  white-space: nowrap;
}

.hecate .images-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 520px;
  top: 15%;
  left: calc(50% - 130px);
  transform: translateX(-50%);
}

.hecate .group-2,
.hecate .group-3 {
  flex: 1; /* Gives equal width to both children */
  margin: 0 10px; /* Adjust margin as needed */
  position: relative;
}

.hecate .image-swap-container {
  display: flex; /* Use flexbox to layout children */
  justify-content: space-around; /* Distribute items evenly */
  align-items: center; /* Align items vertically */
  width: 90%; /* Take the full width of the parent */
}

.hecate .swap-button {
  position: absolute;
  top: 50%; /* Position it 50% from the top */
  left: 50%; /* Position it 50% from the left */
  transform: translate(
    -50%,
    250%
  ); /* Center it both horizontally and vertically */
  align-items: center;
}

.hecate .button-container {
  background: linear-gradient(90deg, rgb(0, 49, 83) 0%, rgb(176, 89, 35) 100%);
  border-radius: 15px;
  height: 70px;
  position: relative;
  width: 570px;
}

.hecate .text-wrapper-6 {
  color: #ffffff;
  font-family: "Apple SD Gothic Neo-Regular", Helvetica;
  font-size: 50px;
  font-weight: 400;
  height: 60px;
  left: 220px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
  white-space: nowrap;
}

.hecate .rectangle-2 {
  background-color: #003153;
  border-radius: 15px;
  height: 400px;
  left: 99px;
  position: absolute;
  top: 0;
  width: 250px;
}

.hecate .rectangle-3 {
  background-color: #b05923;
  border-radius: 15px;
  height: 400px;
  left: 158px;
  position: absolute;
  top: 0;
  width: 250px;
}

.hecate .image {
  height: 300px;
  left: 100px;
  object-fit: cover;
  position: absolute;
  top: 25px;
  width: 215px;
}
.hecate .left-image {
  height: 260px;
  left: 130px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 185px;
}

.hecate .right-image {
  height: 260px;
  left: 190px;
  object-fit: cover;
  position: absolute;
  top: 50px;
  width: 185px;
}

.hecate .text-wrapper-7 {
  color: #ffffff;
  font-family: "Apple SD Gothic Neo-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 30px;
  left: 125px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-decoration: underline;
  top: 330px;
  white-space: nowrap;
}

.hecate .text-wrapper-8 {
  color: #ffffff;
  font-family: "Apple SD Gothic Neo-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  height: 30px;
  left: 184px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-decoration: underline;
  top: 330px;
  white-space: nowrap;
}

.hecate .horizontal-line {
  border: none; /* Remove default border */
  height: 1px; /* Line thickness */
  background-color: gray; /* Line color */
  margin-top: 2px; /* Spacing above the line, adjust as needed */
  margin-bottom: 0px; /* Spacing below the line, adjust as needed */
}

.popup-content {
  text-align: center;
}

.progress-bar {
  width: 80%; /* Adjust as needed */
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin: 20px auto;
  position: relative;
}

.progress {
  width: 0;
  height: 100%;
  background-color: #007aff; /* iOS blue color */
  transition: width 1s;
  border-radius: 4px 0 0 4px; /* Round edges */
}

.result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.popup-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  width: 80%;
  height: 80%;
  padding: 20px;
  border-radius: 20px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(134, 132, 132, 0.5);
  z-index: 999;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  width: 30px; /* Adjust width and height to make the button perfectly round */
  height: 30px;
  border-radius: 50%;
  outline: none;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: rgb(231, 230, 230);
}

.swapped-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.swapped-image {
  max-width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.countdown-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
}

.modal-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.download-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.download-button {
  background-color: #007aff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #0056b3;
}

.warning-box {
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  width: auto;
  z-index: 1000;
}
