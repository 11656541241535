.privacy-page {
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 2rem;
}

.privacy-title {
  white-space: pre-wrap;
  font-family: inherit; /* Prevents monospace font that <pre> uses by default */
  line-height: 1.6; /* Improves readability */
  max-width: 800px; /* Or whatever width works for your design */
  margin-left: 5rem; /* Match margin with title */
  text-align: left; /* Explicitly align left */
  padding: 20px;
  overflow-wrap: break-word; /* Prevents horizontal scrolling on mobile */
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.privacy-content {
  white-space: pre-wrap;
  font-family: inherit; /* Prevents monospace font that <pre> uses by default */
  line-height: 1.6; /* Improves readability */
  max-width: 800px; /* Or whatever width works for your design */
  margin-left: 5rem; /* Match margin with title */
  text-align: left; /* Explicitly align left */
  padding: 20px;
  overflow-wrap: break-word; /* Prevents horizontal scrolling on mobile */
}
