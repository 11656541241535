.main {
  position: relative;
  height: 100vh;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: relative;
  z-index: 10;
  text-align: left;
  color: white;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.content h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.content button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.content button:hover {
  background-color: white;
  color: black;
}
