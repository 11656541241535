.capcam-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.background-pure-black {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  z-index: -1;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  width: 50%;
  padding: 2rem;
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 5%;
}

.content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.content h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.content promotional-text {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  width: 50%;
}

.content button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.content button:hover {
  background-color: white;
  color: black;
}

.right-image {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  max-width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 30%;
}

.image-scroll-container {
  display: flex;
  overflow-x: auto;
  gap: 20px;
  padding: 20px;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on iOS */
  scrollbar-width: thin; /* For Firefox */
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
}

.image-scroll-container::-webkit-scrollbar {
  display: none;
}

.image-scroll-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.image-scroll-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.image-scroll-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.capcam-preview-image {
  flex: 0 0 auto;
  max-height: 400px; /* Adjust this value as needed */
  width: auto;
}

.video-preview {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.preview-video {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.video-preview {
  position: absolute;
  right: 25%;
  top: 50%;
  transform: translate(50%, -50%);
  width: 20%;
  height: auto;
}

.video-preview video {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 2rem;
}

@media screen and (max-width: 768px) {
  .capcam-page {
    flex-direction: column;
    justify-content: center;
    padding: 2rem 1rem;
  }

  .content {
    width: 90%;
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    padding: 1rem;
    text-align: center;
  }

  .content h1 {
    font-size: 2rem;
  }

  .content h2 {
    font-size: 1.5rem;
  }

  .video-preview {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    width: 80%;
    margin: 2rem auto;
  }

  .video-preview video {
    width: 100%;
    border-radius: 1rem;
  }
}
